﻿@media only screen and (max-width : 768px) {
    /*Blog Home Mobile*/ 
   .layout-mobile .related-source h2{
    @include font-heading();
        color: $color-black;
        font-size: 18px;
        padding-bottom: 12px;
        text-transform: uppercase;
    }
   .layout-mobile .people-intro > .blog_problem:last-of-type{
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 7px;
    }

   .layout-mobile .col-r > .tiles:first-of-type{
       padding-top: 20px;
       border-top: 2px solid #231f20;
   }

   .layout-mobile .contact-list{
       margin-bottom: 25px;
   }

   .layout-mobile .people-intro .purple-para p.heading-brief {
        margin-bottom: 20px;
        
    }

   /*Topics Page Mobile*/
   .layout-mobile .collapse-box .people-intro div.blog_name{
       margin-bottom: 20px;
   }

  .layout-mobile .postContainer .purple-para p.heading-brief {
        @include font-content();
        color: rgb(65, 64, 66);
        line-height: 20px;
        font-size: 16px;
        margin: 0 0 20px 0px;
        padding-right: 20px;
    }

   .layout-mobile .wwf{
       background-color: transparent;
        border-bottom: 2px solid #000;
        padding: 0 0 30px 0;
        margin : 0 0 30px 0;
        width: 100%;
   }

   .layout-mobile .blog-info{
        background-color: transparent;
        border-bottom: 2px solid #000;
        padding: 0 0 30px 0;
        margin : 0 0 30px 0;
        width: 100%;
   }

   .layout-mobile .contact-list.blog-info h2,
   .layout-mobile .contact-list.wwf h2,
   .layout-mobile .contact-list.tag-listing h2{
       padding-bottom: 15px;
   }

   .layout-mobile .social-media-link-ls {
        display: block;
	    left: auto;
	    right: 0px;
	    width: auto;
    }
  

   .layout-mobile .social-media-link-ls ul li {
        padding-bottom: 7px;
        float: left;
        margin-left: 10px;
    }
    .layout-mobile .social-media-link-ls ul li a {
        padding-bottom: 7px;
        float: left;
        margin-left: 0px;
    }
     .layout-mobile .social-media-link-ls ul li span {
        
        float: left;
        margin-left: 0px;
    }
   .social-media-link-ls ul li.print{
       display: none;
   }

   .layout-mobile .collapse-box .people-intro .tag-listing{
       background-color: transparent;
       border-bottom: 2px solid #000;
       padding: 0 0 30px 0;
       margin: 40px 0 30px 0
   }
   
   .layout-mobile .postContainerHolder h3 a {
        text-transform: none;
    }

   .layout-mobile .blog_description{
           margin: 10px 0 20px 0;
   }
   .layout-mobile .mainContentContainer .pagination{
           padding: 0 0 40px 0;
   }
   .layout-mobile .container .col-r.last{
	    margin-bottom: 0;
    }
   
   
   /*.layout-mobile .col-r.last .person-depart-info:last-of-type{
      padding-bottom: 30px;
      margin-bottom: 0;
   }*/

   /* Articles */
   .col-r .right-box .related-source h2 {
        @include font-heading();
        color: $color-black;
        font-size: 18px;
        padding-bottom: 12px;
        text-transform: uppercase;
    }

   .col-r .right-box .person-depart-info.blog-info, .col-r .right-box .side-section-listing.blog-info{
       background-color: transparent;
       border-bottom: 2px solid #000;
   }

   .col-l .collapse-box .collapse-expand-a{
       /*margin-top: 35px;*/
       margin-top: 20px;
   }

   .collapse-box .blog-intro .view_more_link{
       margin-bottom: 15px;
   }

   .col-r.last .right-box section.aa {
        display: none;
    }

   .container .col-r.last{
	    margin-bottom: 0;
    }

   /* Contributors */

   .layout-mobile .contributors-listing div.vcard{
       display: none;
   }

   .layout-mobile .contributors-listing div.details {
        width: 70%;
        margin-right: 2%;
        margin-left: 1%;
        float: left;
    }

   .layout-mobile .contributors-listing .m-image {
        height: 102px;
        width: 25%;
        margin-right: 1%;
        margin-left: 1%;
        float: left;
    }
   
   .layout-mobile .contributors-listing div.details p.practices{
       display: none;
   }
   
   .layout-mobile .col-r .contact-list.tag-listing{
	    background-color: transparent;
        border-bottom: 2px solid #000;
        padding: 0 0 30px 0;
        margin : 0 0 30px 0
    }   

   .layout-mobile .contributors-listing .guests-bloggers{
	    /*border-bottom: 2px solid #000;
	    margin-bottom: 30px;*/
    }

   .layout-mobile .contributors-listing .contributors .blog-label{
       margin: 10px 0 10px 0;
   }

   .layout-mobile .collapse-box .people-intro + .contributors-listing .contributors{
       margin-top: 0;
   }

   .layout-mobile .contributors-listing .view_more_link{
       width: 90%;
       margin: 25px 0 35px 0;
       padding-bottom: 25px;
       border-bottom: 2px solid #000;  
   }

   .layout-mobile .people-intro .purple-para .blog-aboutus{
       margin-bottom: 8px;
   }

   .layout-mobile .guests-bloggers .blog-label{
       margin-top: 22px;
   }

}
   /*Layout*/
.layout-mobile .col-r{
       width: 100%;
}

