﻿/* GLOBAL BLOG STYLES - START */
.collapse-box {
    border-bottom: none;
}

/* GLOBAL BLOG STYLES - END */

/* SIDEBAR STYLES - START */

.contact-list .thumbnail-detail p {
    margin-top: 0px;
    line-height: 1.24;
}

.sidebarContentArea {
    padding-left: 5px;
    overflow: hidden;
    color: #000000;
}

.sidebarContentArea img.topAd {
    margin: 0px;
    margin-left: -15px;
    margin-top: -20px;
    width: 225px;
    height: 97px;
    padding: 0px;
    margin-bottom: 20px;
    border: 0px;
    background-color: white;
}

.sidebarContentArea .spacer {
    height: 10px;
}

.sidebarContentArea h3 {
    @include font-heading();
    color: $color-purple;
    padding: 0px;
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 5px 0;
    text-transform: uppercase;
}

.sidebarContentArea h3.topBorder {
    width: 300px;
    padding: 15px 0px 0px 15px;
    margin: 10px 0px 0px -15px;
}

.sidebarContentArea .blue {
    color: #339ac1;
    font-size: larger;
}

.sidebarContentArea {
    @include chevron-bullet();
}

.sidebarContentArea * img.icon1 {
    margin: 0 5px 0 14px;
    padding: 0px 0px;
    border: 0px;
}

.sidebarContentArea * img.icon2 {
    margin: 0 5px 0 0;
    padding: 0px 0px;
    border: 0px;
}

.sidebarContentArea p {
    font-size: 9pt;
    margin: 0;
    padding: 0;
}

.sidebarContentArea p.bio {
    font-size: 8pt;
    margin: 8px 10px 12px 50px;
    padding: 0px 12px 0px 4px;
}

.sidebarContentArea p.bio img {
    margin: 0px 6px 6px -50px;
    padding: 0;
    border: 0;
    float: left;
    height: 54px;
    width: 46px;
}

.sidebarContentArea p.bio span.name {
    font-weight: bold;
    font-size: 10pt;
}

.sidebarContentArea p.bio span.name a {
    text-decoration: none;
    color: #58595b;
}

.sidebarContentArea hr {
    width: 100%;
    color: #eceded;
    height: 1px;
    background-color: #fff;
    margin: 6px 0px;
}

.sidebarContentArea p.topBorder {
    width: 195px;
    padding: 8px 15px 0px;
    margin: 10px 0px 0px -15px;
}

.sidebarContentArea * ul {
    list-style-type: none;
}

.sidebarContentArea ul, .sidebarContentArea div.bookmarks ul {
    margin: 0;
    padding: .5em 0 1em;
    list-style-type: none;
}

.sidebarContentArea li, div.bookmarks li.linklist {
    padding: .25em 0;
    color: #000;
}

.sidebarContentArea li a, div.bookmarks li.linklist a:link, div.bookmarks li.linklist a:visited {
    color: #339ac1;
    text-decoration: none;
}

.sidebarContentArea li a:hover, .sidebarContentArea div.bookmarks li.linklist a:hover {
    text-decoration: none;
}

.sidebarContentArea .sidebarList a, 
.sidebarContentArea .sidebarList a:link, 
.sidebarContentArea .sidebarList a:visited {
    @include font-semibold();
    color: #000000;
    text-decoration: none;
    font-size: 17px;
    line-height: 19px;
}

.sidebarContentArea .sidebarList a:hover {
    text-decoration: none;
}

.sidebarContentArea ul {
    margin: 0px;
    padding: 0px;
}

.sidebarContentArea ul li {
    margin: 0 0 4px 0;
    padding: 0px 0 0 12px;
    background-image: url(/contents/images/gray_dot.jpg);
    background-repeat: no-repeat;
    background-position: 0 7px;
    list-style-type: none;
}

.wwf .section {
    margin-top: 7px; 
    font-size: 15px; 
    font-weight: bold;
}

/* SIDEBAR STYLES - END */

/* CONTRIBUTORS STYLES - START */

.clear_left {
    clear: left;
    height: 0px;
}

.contributors {
    position: relative;
    display: block;
    float: right;
    width: 101%;
}

.contributors-listing{
    width: 101%;
    position: relative;
    height: 100%;
}

.contributors-listing .bg-light-grey{
    float: left;
    padding: 10px 0;
    width: 100%;
    background-color: #f7f7f7;
}

.contributors-listing .bg-white{
    float: left;
    padding: 10px 0;
    width: 100%;
}

.contributors-listing .m-image{
    width: 17%;
    margin-right: 1%;
    margin-left: 1%;
    float: left;
}

.contributors-listing .m-image img{
    width: 100%;
    max-height: 100%;
}

.contributors-listing div.details{
    width: 47%;
    margin-right: 2%;
    margin-left: 1%;
    float: left;
}

.contributors-listing div.details h3{
    @include font-heading();
    font-size: 16px;
    font-size: 18px;
    margin-bottom: 3px;
    text-transform: uppercase;
}

.contributors-listing div.details p.position-loc{
    @include font-heading();
    color: $color-black;
    font-size: 14px;
    margin-bottom: 1px;
    text-transform: uppercase;
    margin-top: 0;
}

.contributors-listing div.details p.practices{
    @include font-content();
    font-size: 12px;
    line-height: 14px;
    margin-top: 5px;
}


.contributors-listing div.details p.loc-ph{
    font-size: 14px;
    margin-bottom: 0;
    margin-top:0;
}

.contributors-listing div.details p.loc-ph a{
    text-decoration: none;
}

.contributors-listing div.vcard{
    width: 29%;
    padding-top: 28px;
    float: left;
}

.contributors-listing div.vcard .mail-id-image{
    margin-top: 0;
    display: inline-block;
}

.contributors-listing div.vcard p.v-card{
    @include font-semibold();
    padding: 0;
    margin-top: 0;
}

.contributors-listing .view_more_link{
    @include chevron-bullet();
    a {
        padding-left: 0 !important;
    }
}

.blog-label{
    @include font-heading();
    margin: 42px 0 10px 0;
    font-size: 24px;
    text-transform: uppercase !important;
    padding-bottom: 0 !important;
}

.blog-label h2{
    @include font-heading();
    font-size: 24px;
    text-transform: uppercase !important;
    padding-bottom: 0 !important;
}

.guests-bloggers{
    position: relative;
    display: block;
    float: right;
    width: 101%;
}

.guests-bloggers .blog-label{
    margin: 30px 0 10px 0;
}

/* CONTRIBUTORS STYLES - END */

/* BLOG TOPIC LISTING START */
.blog_problem {
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 15px;
    margin-bottom: 15px;
    margin-left: 0px;
    width: 100%;
}

.blog_problem_title {
    @include font-heading();
    color: $color-black;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 5px;
    text-decoration: none;
}

.blog_problem_description {
    @include font-content();
    font-size: 16px;
    line-height: 20px;
}

.blog_problem .view_more_link{
    margin-top: 5px;

    @include for-print() {
        display: none;
    }
}

.view_more_link {
    @include chevron-bullet();
    margin: 20px 0 0 0;
}

.view_more_link a:link {
    @include font-semibold();
    color: #000000;
    font-size: 16px;
    padding-left: 0;
    text-decoration: none !important;
}

.view_more_link a:hover,
.view_more_link a:active,
.view_more_link a:focus {
    color: $color-purple;
    text-decoration: underline;
}

.postContainer .blogpost-body .view_more_link:hover, 
.postContainer .blogpost-body .view_more_link:focus, 
.postContainer .blogpost-body .view_more_link:active{
     background-image: url(../images/arrow-light-grey1-active.png);
}

.postContainer .blogpost-body .view_more_link a:hover,
.postContainer .blogpost-body .view_more_link a:focus,
.postContainer .blogpost-body .view_more_link a:active{
    color: #000;
}

.postContainer p.view_more_link {
    margin: 0;
}

.postContainer ul + p.view_more_link {
    margin: 20px 0 0 0;
}

.people-intro{
    padding-bottom: 0;
    padding-top: 0px!important;
}


.people-intro h1{
    padding-bottom: 10px;
}

.people-intro .purple-para p.heading-brief {
    @include font-semibold();
    color: $color-purple;
    font-size: 20px;
    margin: 0 0 25px 0;
    padding-right: 20px;
    line-height: 24px;
}

/* Blog Topic Listing Listing End */

/* BLOG ARTICLE LISTING START */
.blog_name {
    @include font-heading();
    color: $color-black;
    margin: 0 0 10px 0;
    padding: 0;
    font-size: 48px;
    line-height: 48px;

    h1 {
        @include font-heading();
        color: $color-black;
        font-size: 48px;
        line-height: 48px;
        margin: 0 0 10px 0;
        padding: 0;
        text-decoration: none;
        text-transform: uppercase;
    }
}

.blog_description {
    @include font-heading();
    color: $color-black;
    margin: 10px 0 30px 0;
    padding: 0px;
    font-size: 16px;
    line-height: 1.24;
}

.blog_name a {
    text-decoration: none;
    color: #000000;
    text-transform: uppercase;
}

.postContainerHolder h3 {
    @include font-semibold();
    margin: 0;
    padding: 0px 20px 5px 0;
    color: $color-purple;
    line-height: 24px;

    .blog-subtitle {
        font-size: 18px;
    }
}


.postContainerHolder h3 a{
    @include font-semibold();
    color: $color-purple;
    font-size: 18px;
    line-height: 22px;
    text-decoration: none;
}



.postContainerHolder ul li{
    margin: 10px 0 0 15px;
    padding: 0px;
    list-style-type: disc;
}

.postContainerHolder ol{
    margin: 0px 0 10px 20px;
    padding: 0px;
}

.postContainerHolder ol li{
    margin: 10px 0 0 15px;
    padding: 0px;
    list-style-type: decimal;
}




p.view_more_link a:focus,
p.view_more_link a:active,
p.view_more_link a:hover {
    color: $color-purple;
    text-decoration: underline;
}


.postContainer div.metaInfo {
    color: #666666;
    font-size: 11pt;
    margin: 0;
    padding: 0 0 13px 0;
}

.postContainer div.metaInfo a {
    @include font-semibold();
    color: #000000;
    text-decoration: none;
}

.postContainer {
    margin-top: 0px;
    padding-top: 10px;/*JPM*/
    padding-bottom: 0px;
    text-align: left;
}

.mainContentContainer {
    float: left;
    width: 100%;
    padding: 0px;
}

.mainContentContainer .pagination{
    padding: 0 0 60px 0;
}

p {
    @include font-content();
    color: rgb(65, 64, 66);
    font-size: 16px;
    margin-bottom: 16px;
    margin-top: 16px;
    line-height: 20px;
}

.clear_left {
    clear: left;
    height: 0px;
}

.postContainer .postFooter {
    @include font-content();
    display: block;
    padding: 0 0 20px 0;
    margin: 0 0 7px 0;/*JPM*/
    font-size: 12px;
    color: #666666;
    border-bottom: 1px solid #CCCCCC;
}

.postContainer .postFooter a {
    @include font-semibold();
    color: #000000;
    font-size: 12px;
    text-decoration: none;
}

.read_more_link {
    padding-left: 14px;
    background-image: url("../images/read_more_arrow.jpg");
    background-repeat: no-repeat;
    background-position: 0 3px;
}

a.more-link {
    text-decoration: none;
    font-size: 16px;
    color: #000000;
    font-weight: bold;
}

a.more-link:hover {
    color: $color-purple;
    text-decoration: underline;
}

.postContainerHolder .blogpost-body{
    @include font-content();
    line-height: 20px;
    padding-top: 5px;
}

.blogpost-body {
    display: block;
    .blogpost-description{
        a{
            color: #000;
            text-decoration: underline;
            &:hover{
                color: $color-purple;
                text-decoration: none;
            }
        }
    }
    &__left {
        float: left;
        margin-right: 1em;
    }

    &__highlight {
        align-items: flex-start;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        height: 91px;
        line-height: 1em;
        padding-top: 15px;
        width: 132px;
        // @media print{
        display: block;
        // margin-right: 0em;
        // }
        div {
            align-items: center;
            background-color: #000000;
            display: flex;
            line-height: 1.1em;
            min-height: 2em;
            padding-bottom: .5em;
            padding-left: 1em;
            padding-right: 1em;
            padding-top: .5em;
            text-align: center;
        }

        span {
            @include font-content();
            color: #FFFFFF;
            font-size: 14px;
            letter-spacing: 0.28px;
            text-align: center;
            text-transform: uppercase;
            width: 100%;
        }
    }
}

.postContainerHolder .blogpost-body .heading-brief{
    @include font-content();
    color: rgb(65, 64, 66);
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 0;
    line-height: 20px;
}
/* BLOG ARTICLE LISTING END */

/* ABOUT US START */
 .blog-aboutus{
    margin: 42px 0 20px 0;
    font-size: 24px;
    text-transform: uppercase !important;
    padding-bottom: 0 !important;
}

h1.blog-aboutus {
    @include font-heading();
    margin: 42px 0 10px 0;
    font-size: 18pt !important;
    text-transform: uppercase !important;
    padding-bottom: 0 !important;
}

.people-intro .purple-para .blog-aboutus{
    margin-top: 0;
}

.collapse-box .people-intro div.blog_name{
	margin: 0;
	padding: 0;
}

.collapse-box .people-intro + .contributors-listing .contributors {
    margin-top: -30px;
}

.contributors-listing .vcard .trib_email {
    display: none;
}

.contributors-listing div.vcard .mail-id-image a {
    text-decoration: none;
}
/* ABOUT US END */

/* ARTICLE DETAILS START */

.collapse-box.no-border{
    padding-right: 3%;
}

.collapse-box{
    padding-right: 3%;
    padding-top:0px!important;
}

.blogpost-body{
    @include font-content();
    line-height: 20px;
    padding-top: 20px;


}

.postFooter {
    @include font-content();
    display: block;
    padding: 0 0 20px 0;
    margin: 0 0 18px 0;
    font-size: 12px;
    color: #666666;
}

.postFooter a {
    @include font-semibold();
    color: #000000;
    font-size: 12px;
    text-decoration: none;
}

.purple-para p.blogpost-body,
.purple-para p.heading-brief {
    @include font-content();
    color: rgb(65, 64, 66);
    font-size: 16px;
    margin: 0 0 20px 0px;
    line-height: 20px;
}

.postContainer .postContainerHolder .blogpost-heading .metaInfo .blogpost-contributors{
    display: inline;
}
.blog-intro .blogpost-heading .metaInfo .blogpost-contributors .contributor-names {
    display: none;
}
.blog-intro .blogpost-heading .metaInfo .blogpost-contributors .timeStamp text {
    display: none;
}
.blog-intro .blogpost-heading .metaInfo .timeStamp{
    display: inline !important;
}

.blogpost-heading .timeStamp{
    @include font-content();
    display: inline-block;
    font-size: 14px;
}

.postContainer .postContainerHolder .blogpost-heading .metaInfo .blogpost-contributors,
.postContainer .postContainerHolder .blogpost-heading .metaInfo .blogpost-contributors a {
    display : inline-block;
}

.postContainer .postContainerHolder .blogpost-heading .timeStamp {
    @include font-content();
    color: $color-dark-grey;
    display : inline-block;
    font-size: 14px;
}

.postContainer .blogpost-body p{
    margin-top:0px;
    margin-bottom: 13px;
}

.postContainer .blogpost-body p.view_more_link {
    margin-top: 5px;
    margin-bottom: 0px;
}

.blog-intro .page-heading {
    padding-bottom: 0;
}

.blog-intro .page-heading .blog_description {
    margin: 10px 0 15px 0;
}

.blog-intro .page-heading + .blogpost-heading {
    margin-bottom: 5px;
}

p.heading-brief{
    margin-bottom: 0;
}

.blog-intro p.blogpost-body{
    margin: 30px 0 40px 0px;
}

.blogpost-body ul li{
    background: url(../images/arrow-light-grey1.png) no-repeat 0 5px;
    text-decoration: none;
    font-size: 16px;
    color: rgb(65, 64, 66);
}
.blogpost-body ul li ol li{
    padding: 0px;
    background:none;
    display: list-item;
}

.blogpost-body ul li a{
	text-decoration:underline;
}
.blogpost-body ol{
    margin: 0px 0 10px 20px;
    padding: 0px;
}

.blogpost-body ol li{
    margin: 10px 0 0 15px;
    list-style-type: decimal;
}

.blog-intro .blogpost-body ul {
    margin-bottom: 10px;
}

.blogpost-body ul {
    margin-bottom: 15px;
}

.blog-intro .blogpost-heading h3 a {
    @include font-semibold();
    color: $color-purple;
    font-size: 24px;
    line-height: 28px;
    text-decoration: none;
}

.blog-intro .blogpost-heading h3 {
    @include font-semibold();
    margin: 0;
    color: $color-purple;
    line-height: 24px;

    .blog-subtitle {
        font-size: 24px;
        line-height: 28px;
    }
}

.collapse-box .blog-intro .view_more_link {
    margin-top: 0;
}
.collapse-box .blog-intro .postFooter {
    margin: 0 0 10px 0;
}

sup{
	font-size: 10px;
	vertical-align: super;
}

.blog-intro ul li a:hover {
    text-decoration: none;
}
.blog-intro ul li a {
    text-decoration: underline;
}

.blog-intro .blogpost-body table{
    border-collapse: separate;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-spacing: 1px;
}

.blog-intro .blogpost-body table td,
.blog-intro .blogpost-body table th{
    padding: 3px;
    border: 1px solid lightgrey;
    border-left-color: grey;
    border-top-color: grey;
}

/* ARTICLE DETAILS END */

/* SOCIAL MEDIA SHARE START */

.blog-share {
    margin-top: 30px; 
    margin-bottom: 10px;
    display: flex; 
}

.blog-share .st-custom-button[data-network="twitter"]  {
    margin-bottom: 12px;
    margin-left: 10px;
}

.blog-share ul li {
    list-style: none;
    list-style-type: none;
    display: inline-block;
    margin: 0px 0px;
}

.postContainerHolder .blog-share ul li {
    margin: 0px 0px;
}

.blog-share .st_twitter_large .stLarge{
    margin-left: 0px;
}
.blog-share .st_linkedin_large .stLarge{
    margin-left: 0px;
}

.blog-share .st_facebook_large .stLarge {
    margin-left: 0px;
}

.li_email_large {
    margin: 0 10px!important;
    vertical-align: top!important; 
    height:36px!important;
    width:35px!important;
    outline: none!important;
}

.li_email_large a {
    text-indent: -99999px!important;
    float:left!important;
    margin-left: 0px;
}

/* SOCIAL MEDIA SHARE END */

/* FONT AUTO DETECT START - JAPANESE */

.postContainerHolder.ja h3,
.postContainerHolder.ja h3 a,
postContainer .ja div.metaInfo a,
.postContainer .ja .postFooter a,
.ja .postFooter a {
    @include font-semibold();
}


.postContainer .ja .postFooter,
.postContainerHolder.ja .blogpost-body,
.postContainerHolder.ja .blogpost-body .heading-brief,
.ja .blogpost-body {
    @include font-content();
}

.ja .purple-para .blogpost-body p.heading-brief {
    @include font-content();
}
.ja p,
.ja p.heading-brief,
.ja .purple-para ul li {
    @include font-content();
}

.en p{
    @include font-content();
}

.ja .blog_problem_title,
.ja .blog_problem_title a{
    @include font-heading();
}

.ja .blog_problem_description{
    @include font-content();
}

.ja .purple-para p.heading-brief {
    @include font-semibold();
}

.ja .blogpost-heading .timeStamp{
    @include font-content();
}

.blog-intro.ja .page-heading,
.blog-intro.ja .page-heading h2{
    @include font-heading();
}
.ja .blog-intro .page-heading + .blogpost-heading{
    @include font-content();
}

.postContainer .postContainerHolder.ja .blogpost-heading .timeStamp {
    @include font-content();
}



.ja .blogpost-body .view_more_link a:link,
.ja .blog-intro .view_more_link a:link,
.ja .contributors-listing .view_more_link a:link{
    @include font-semibold();
}

.ja .blog-intro .page-heading,
.ja .blog-intro .page-heading h2{
    @include font-heading();
}

.ja .blog-aboutus{
    @include font-heading();
}

.ja .blog-label h2,
.ja .blog-label{
    @include font-heading();
}

.tag-listing ul li.ja a,
.wwf ul li.ja a,
.blog-info ul li.ja a{
	@include font-semibold();
}

.contributors-listing .ja div.details p.position-loc{
    @include font-heading();
}

.tag-listing .ja .show-more-link a{
    @include font-heading();
}

.contributors-listing .ja div.details h3,
.contributors-listing .ja div.details h3 a{
    @include font-heading();
}

/* FONT AUTO DETECT END - JAPANESE */


/* FONT AUTO DETECT START - CHINESE - SIMPLIFIED */

.postContainerHolder.zh-cn h3,
.postContainerHolder.zh-cn h3 a,
postContainer .zh-cn div.metaInfo a,
.postContainer .zh-cn .postFooter a,
.zh-cn .postFooter a {
    font-family: $font-simplified-chinese-content, arial !important;
}


.postContainer .zh-cn .postFooter,
.postContainerHolder.zh-cn .blogpost-body,
.postContainerHolder.zh-cn .blogpost-body .heading-brief,
.zh-cn .blogpost-body{
    font-family:  $font-simplified-chinese-content, arial;
}

.zh-cn .purple-para .blogpost-body p.heading-brief{
    font-family: $font-simplified-chinese-content, arial !important;

}
.zh-cn p,
.zh-cn p.heading-brief,
.zh-cn .purple-para ul li {
    font-family: $font-simplified-chinese-content, arial;
}

.en p{
    @include font-content();
}

.zh-cn .blog_problem_title,
.zh-cn .blog_problem_title a{
    font-family: $font-simplified-chinese-content;
    font-weight: 600;
}

.zh-cn .blog_problem_description{
    font-family: $font-simplified-chinese-content, arial;
}

.zh-cn .purple-para p.heading-brief {
    font-family: $font-simplified-chinese-content, Arial !important;
}

.zh-cn .blogpost-heading .timeStamp{
    font-family: $font-simplified-chinese-content, Arial;
}

.blog-intro.zh-cn .page-heading,
.blog-intro.zh-cn .page-heading h2{
    font-family: $font-simplified-chinese-content!important;
    font-weight: 600!important;
}
.zh-cn .blog-intro .page-heading + .blogpost-heading{
    font-family: $font-simplified-chinese-content, Arial;
}

.postContainer .postContainerHolder.zh-cn .blogpost-heading .timeStamp {
	font-family: $font-simplified-chinese-content, Arial;
}


.zh-cn .blog-intro .blogpost-heading h3 a {
    font-family: $font-simplified-chinese-content !important;
    font-weight: 600;
}

.zh-cn .blogpost-body .view_more_link a:link,
.zh-cn .blog-intro .view_more_link a:link,
.zh-cn .contributors-listing .view_more_link a:link{
    font-family: $font-simplified-chinese-content;
    font-weight: 600;
}

.zh-cn .blog-intro .page-heading,
.zh-cn .blog-intro .page-heading h2{
    font-family: $font-simplified-chinese-content!important;
    font-weight: 600!important;
}

.zh-cn .blog-aboutus{
	font-family: $font-simplified-chinese-content!important;
    font-weight: 600;
}

.zh-cn .blog-label h2,
.zh-cn .blog-label{
	font-family: $font-simplified-chinese-content!important;
    font-weight: 600;
}

.tag-listing ul li.zh-cn a,
.wwf ul li.zh-cn a,
.blog-info ul li.zh-cn a{
	font-family: $font-simplified-chinese-content;
    font-weight: 600;
}

.contributors-listing .zh-cn div.details p.position-loc{
	font-family: $font-simplified-chinese-content;
    font-weight: 600;
}

.tag-listing .zh-cn .show-more-link a{
	font-family: $font-simplified-chinese-content;
    font-weight: 600;
}

.contributors-listing .zh-cn div.details h3,
.contributors-listing .zh-cn div.details h3 a{
    font-family: $font-simplified-chinese-content;
    font-weight: 600;
}
/* FONT AUTO DETECT END - CHINESE - SIMPLIFIED */

.blog-title-image {
    height: 100px;
    float: left;
    margin-right: 8px;
}

@media screen and (max-width: 480px) {
    .blog-title-image {
        display: none;
    }
}
.blog-filter{
    margin-bottom: 30px;
    &__label{
        margin-right: .3em;
        &::after{
            content: ":";
        }
    }
    &__value{
        @include font-semibold();
    }
}

.social {
    &__column {
        float: left;
        padding-top: 15px;
        padding-bottom: 13px;
        padding-right: 8px;
        padding-left: 50px;
        color: #000;
    }

    &__icon-column {
        float: left;
        width: 10%;
        padding: 10px;
    }
    
    &__row{
        background-color: #f0f0f0;
        border-radius: 25px;
        margin-bottom:60px;
        &:after {
        content: "";
        display: table;
        clear: both;
        }
    }
    &__label{
        @include font-heading();
        font-size: 18px;
        text-transform: uppercase;
    }
}

.blog-filter {
    margin-bottom: 20px;
    margin-top: 30px;  
 }









