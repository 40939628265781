﻿

.print-wrapper .blog-share{
        display: none;
}

.print-wrapper .mainContentContainer .pagination{
    display: none;
}

.print-wrapper .blog-intro .blogpost-heading .metaInfo {
    margin-bottom: 15px;
}

.mainContentContainer .mainContentArea .keycontact-print {
    padding-bottom: 20px;
}

.mainContentContainer .mainContentArea .blog_description{
    margin-bottom: 20px;
}

.print-wrapper .print-footer{
    padding-top:0;
    margin-top: -15px;
}
/* Start - Blog Home Page Print */
.print-wrapper .collapse-box .people-intro h1{
    padding-bottom: 25px;
}

.print-wrapper .keycontact-print{
    padding-bottom: 10px;
}

.print-wrapper .blog_problem_title{
    margin-bottom: 5px;
}

.print.print-wrapper .blog_problem{
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.print-wrapper .blog_problem .view_more_link{
    /*margin-top: 15px;*/
    margin-top:5px;
}

.print-wrapper .blog_problem .blog_problem_description{
    font-size: 14px;
    line-height: 18px;
}
.print-wrapper .blog_problem .blog_problem_title{
    font-size: 18px;
}

.print-wrapper .blog_problem + div.related-source{
    margin-top:30px;
}

.print-wrapper .collapse-box  .intro-space p.heading-brief{
    line-height: 18px !important;
}
/* End - Blog Home Page Print */

/*Start - Blog landing page print view*/
.print-wrapper .blog_name,
.print-wrapper .blog_name h1{
    font-size: 36px;
}

.print-wrapper .postContainerHolder .blogpost-body .heading-brief {
    @include font-content();
    color: rgb(65, 64, 66);
    font-size: 14px;
    margin-bottom: 10px;
    /*margin-top: 16px;*/
    line-height: 18px;
}

.print-wrapper .blogpost-body p{
    @include font-content();
    color: rgb(65, 64, 66);
    font-size: 14px;
    margin-bottom: 16px;
    /*margin-top: 16px;*/
    line-height: 18px;
}

.print-wrapper .collapse-box .person-depart-info.contact-list ul li,
.print-wrapper .collapse-box .side-section-listing.contact-list ul li
{
    display: block;
    padding-bottom: 7px;
}

.print-wrapper .content-wrapper .collapse-expand-a .person-depart-info > ul > li > a,
.print-wrapper .content-wrapper .collapse-expand-a .side-section-listing > ul > li > a {
    @include font-semibold();
    display: block;
    line-height: 18px;
    font-size: 16px;
    color: #000 !important;
}

.print-wrapper .person-depart-info > ul > li > a,
.print-wrapper .side-section-listing > ul > li > a
{
    font-size: 16px;
}

.print-wrapper .postContainer{
    padding-top:0;
}

.print-wrapper .postContainer .postFooter{
    margin: 0 0 18px 0;
}

.print-wrapper .postContainer .blogpost-body p.view_more_link{
    line-height: 1.54;
}
/* End - Blog landing page print view*/

/* Start Blog Post Print */
.print-wrapper .blog-intro .page-heading + .blogpost-heading {
    margin-bottom: 20px;
}

.print-wrapper .blog-intro .page-heading ~ .keycontact-print {
    padding: 0;
    margin-bottom: -10px;
}

.print-wrapper .blog_intro .view_more_link a {
    @include font-semibold();
    color: #000000;
    font-size: 16px;
    text-decoration: none !important;
}

.print-wrapper .blog-intro .page-heading .blog_description {
    margin: 10px 0 10px 0;
}

/* End Blog Post Print */

/* Start About us and Contributors*/
.print-wrapper .contributors-listing .contributors .blog-label{
    margin: 30px 0 10px 0;
}

.print-wrapper .blog-label h2{
    font-size: 20px;
}
.print-wrapper .collapse-box .people-intro .purple-para .blog-aboutus ~ p,
.print-wrapper .collapse-box .people-intro .purple-para .blog-aboutus + p.heading-brief{
    font-size: 14px;
    line-height: 18px !important;
}

.print-wrapper .contributors-listing p.v-card{
    display: block;
}

.print-wrapper .contributors-listing .vcard .trib_email{
    display: block;
    float: right;
}

.print-wrapper .contributors-listing .vcard .mail-id-image{
    display: none;
}


.print-wrapper .contributors-listing p.v-card{
    float: right;
}

.trib_email_link {
    @include font-semibold();
    font-size: 14px;
}

.print-wrapper .contributors-listing .view_more_link{
    margin: 0 0 0 0;
    visibility: hidden;
}

.print-wrapper .people-intro .purple-para .blog-aboutus {
    margin-top: -5px;
}
/* End About us and Contributors*/

/* Print Auto Detect */

/*Blog Article*/
.print-wrapper .detail-page .blog-intro.ja .col-l h1 {
    @include font-content();
}

.print-wrapper .blog-intro.ja .page-heading,
.print-wrapper .blog-intro.ja .page-heading h2 {
    @include font-content();
}

.print-wrapper .blog-intro.ja .page-heading .blog_description {
    @include font-content();
}

.print-wrapper .blog-intro.ja .page-heading + .blogpost-heading {
    @include font-content();
}

.print-wrapper .ja .blogpost-body,
.print-wrapper .ja .blogpost-body .heading-brief,
.print-wrapper .blog-intro.ja .purple-para p {
    @include font-content();
}

.print-wrapper .blog-intro.ja .purple-para {
    @include font-content();
}

.print-wrapper .blog-intro.ja .view_more_link a:link {
    @include font-content();
}

/* Topic */
.print-wrapper .postContainer .postContainerHolder.ja .blogpost-heading,
.print-wrapper .postContainer .postContainerHolder.ja .blogpost-heading .heading-brief,
.print-wrapper .postContainer .blogpost-heading .blogpost-contributors,
.print-wrapper .collapse-box .postContainerHolder.ja .p {
    @include font-content();
}

/* Blog Landing*/

.print-wrapper .ja .blog_problem_description {
    @include font-content();
}

.print-wrapper .ja .blog_problem_title,
.print-wrapper .ja .blog_problem_title a,
.print-wrapper .view_more_link,
.print-wrapper .view_more_link a {
    @include  font-semibold();
}

.print-wrapper{
    .print-participant, .keycontact-print span.print-participant{
        @include  font-semibold();
    }
}
/* About Us*/

/* Print Auto Detect - END */

